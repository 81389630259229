import { Component, Mixins, Vue } from "vue-property-decorator";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import PasswordModify from "@/store/password/modify";
import { ResetCheckRequest, ResetRequest } from "@/api/password/request";
import Flash, { ErrorAlert } from "@/store/common/flash";
import Loading from "@/store/loading";
// import { BusinessServiceStorage } from "@/store/business-service-storage";

@Component
export default class index extends Mixins(RedirectWithAlert) {
  // ロゴ画像
  // logoImagePath = null as string | null;
  // パスワード
  password = null as string | null;

  // パスワード（確認用）
  confirmPassword = null as string | null;

  get loading() {
    return Loading.isLoading;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    const checkId = this.$route.query["check_id"];
    await PasswordModify.resetCheck({ checkId } as ResetCheckRequest);
    if (!PasswordModify.isSuccess) {
      await Flash.setErrorNow({
        message: PasswordModify.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }
//ロゴ画像表示
  // async mounted() {
  //   const storage = BusinessServiceStorage.getSessionStorage();
  //   this.logoImagePath = storage.logoImagePath ? storage.logoImagePath : null;
  // }
  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await PasswordModify.clearResponse();
  }

  /**
   * 保存ボタンのイベントハンドラー
   */
  async save() {
    await Flash.clear();
    const checkId = this.$route.query["check_id"];
    await PasswordModify.reset({
      checkId: checkId,
      password: this.password,
      passwordConfirm: this.confirmPassword
    } as ResetRequest);

    if (PasswordModify.isSuccess) {
      await this.redirectWithSuccessAlert(
        "パスワードを再設定しました。",
        "/login"
      );
    } else {
      await Flash.setErrorNow({
        message: PasswordModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }
}
