import service from "@/api/service";
import {
  ResetCheckRequest,
  ResetMailRequest,
  ResetRequest
} from "@/api/password/request";
import { CommonResponse } from "@/api/response";

/**
 * パスワード再設定メール送信APIをコールします。
 *
 * @param resetMailRequest リクエストボディ
 */
export async function resetMail(resetMailRequest: ResetMailRequest) {
  const response = await service.post("/password-reset-mail", resetMailRequest);
  return response.data as CommonResponse;
}

/**
 * パスワード再設定チェックAPIをコールします。
 *
 * @param resetCheckRequest リクエストボディ
 */
export async function resetCheck(resetCheckRequest: ResetCheckRequest) {
  const response = await service.post(
    "/password-reset-check",
    resetCheckRequest
  );
  return response.data as CommonResponse;
}

/**
 * パスワード再設定APIをコールします。
 *
 * @param resetRequest リクエストボディ
 */
export async function reset(resetRequest: ResetRequest) {
  const response = await service.post("/password-reset", resetRequest);
  return response.data as CommonResponse;
}
